import firebase from "firebase/app"
import "firebase/auth"
import "firebase/storage"
import "firebase/firestore"

var config = {
  apiKey: "AIzaSyDUAxE0BNxC65axkX7GoiJqWdQFMg3ASig",
  authDomain: "re-lilly-7effc.firebaseapp.com",
  databaseURL: "https://re-lilly-7effc.firebaseio.com",
  projectId: "re-lilly-7effc",
  storageBucket: "re-lilly-7effc.appspot.com",
  messagingSenderId: "868283275456",
  appId: "1:868283275456:web:c4b70646667070565ef525",
}
firebase.initializeApp(config)

const storage = firebase.storage()
const db = firebase.firestore()

export { firebase, storage, db }
