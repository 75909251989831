import styled from "styled-components"

export const Container = styled.div`
  margin-top: 7.5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  &.working {
    color: var(--working);
  }

  &.error {
    color: var(--error);
  }

  &.notice {
    color: var(--notice);
  }

  &.success {
    color: var(--success);
  }

  & > span {
    padding-top: 3.5px;
    padding-bottom: 15px;
  }
`
