import styled from "styled-components"

export const Container = styled.div`
  & button {
    width: 100%;
    background-color: var(--blue);
    color: #fff;
    padding: 7.5px 0 6.5px 0;
    border-color: var(--blue);
    border-radius: 2px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1rem;
    letter-spacing: 0.75px;

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;

      & > span {
        padding-right: 7.5px;
        text-transform: uppercase;
        letter-spacing: 0.5px;
      }
    }
  }
`
