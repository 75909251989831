import React from "react"
import { Container } from "./styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCircleNotch,
  faTimes,
  faCheck,
} from "@fortawesome/pro-light-svg-icons"

const Msg = ({ data }) => {
  return (
    <Container className={data.type}>
      {data.type === "working" ? (
        <FontAwesomeIcon icon={faCircleNotch} size="lg" spin />
      ) : data.type === "success" ? (
        <FontAwesomeIcon icon={faCheck} size="lg" />
      ) : data.type === "notice" ? (
        <FontAwesomeIcon icon={faCheck} size="lg" />
      ) : (
        <FontAwesomeIcon icon={faTimes} size="lg" />
      )}
      {data.text && <span>{data.text}</span>}
    </Container>
  )
}

export default Msg
